/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get(data) {

    return api.get(`phone_model/list?search=${data.search}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  all(data = null) {
    var url = `phone_model/get_all`;
    if(data.search){
      url += `?search=${data.search}`
    }
    return api.get(url)
  },

  show(data){
    return api.get(`phone_model/show?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  store(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`phone_model/store`, params, config)
  },

  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }

    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return api.post(`phone_model/update/${data.phone_model_id}`, params, config)
  },

  destroy(id) {
    // let getFormData = data


    let params = new FormData();
    // params.append('_method', 'DELETE');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }

    return api.delete(`phone_model/delete/${id}`, params, {})
  },

  updateStatus(id) {
    // let getFormData = data

    let params = new FormData();
    params.append('_method', 'put');
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }

    return api.post(`phone_model/phone_model_updateStatus/${id}`, params, {})
  },

}
