<template>
  <v-data-table
    :headers="headers"
    :items="phone_models"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มรุ่นโทรศัพท์
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    v-if="editedItem.phone_model_img"
                    cols="12"
                    sm="12"
                    md="12">
                    <v-img
                      :src="editedItem.phone_model_img"
                    >

                    </v-img>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <v-file-input
                      v-model="editedItem.file"
                      show-size
                      accept="image/png, image/jpeg, image/bmp"
                      label="รูปรุ่นโทรศัพท์">
                    </v-file-input>

                  </v-col>


                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-select
                      v-model="editedItem.phone_brand_id"
                      :items="phone_brands"
                      item-text="phone_brand_name"
                      item-value="phone_brand_id"
                      label="ชื่อยี่ห้อโทรศัพท์"
                    ></v-select>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.phone_brand_id.$silentErrors"
                      v-if="v$.editedItem.phone_brand_id.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.phone_model_name"
                      placeholder="ชื่อรุ่นโทรศัพท์"
                      label="ชื่อรุ่นโทรศัพท์"
                    ></v-text-field>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.phone_model_name.$silentErrors"
                      v-if="v$.editedItem.phone_model_name.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>

    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        @click="UpdateStatus(item.phone_model_id)"
        v-model="item.phone_model_status"
      ></v-checkbox>
    </template>
    <template v-slot:item.image="{ item }">
      <v-img
        width="50"
        :src="item.phone_model_img"
      ></v-img>
    </template>

    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_phone_models from '../../../services/phoneModel'
import instance_phone_brands from '../../../services/phoneBrand'
import Swal from "sweetalert2";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    phone_models: [],
    phone_brands: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ยี่ห้อโทรศัพท์', value: 'phone_brand.phone_brand_name', sortable: false},
      {text: 'รุ่นโทรศัพท์', value: 'phone_model_name'},
      {text: 'สถานะ', value: 'status'},
      {text: 'รูปภาพ', value: 'image', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    editedIndex: -1,
    editedItem: {
      file: null,
      phone_brand_id: '',
      phone_brand_name: '',
      phone_model_id: '',
      phone_model_img: '',
      phone_model_name: '',
      phone_model_status: 'N',
    },
    defaultItem: {
      file: null,
      phone_brand_id: '',
      phone_brand_name: '',
      phone_model_id: '',
      phone_model_img: '',
      phone_model_name: '',
      phone_model_status: 'N',
    },
  }),
  validations() {
    return {
      editedItem: {
        phone_brand_id: {required},
        phone_model_name: {required},
      }
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม รุ่นโทรศัพท์' : 'แก้ไข รุ่นโทรศัพท์'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getPhoneModel()
      },
    },
    search: {
      handler() {
        this.getPhoneModel()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPhoneModel()
    this.getPhoneBrand()
  },

  methods: {
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.phone_models.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.phone_models.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    UpdateStatus(id) {
      this.$store.state.isLoading = true
      instance_phone_models.updateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      });
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_phone_models.destroy(this.editedItem.phone_model_id).then(res => {
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          },150);
        this.getPhoneModel()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {
        instance_phone_models.update(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.getPhoneModel()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_phone_models.store(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.getPhoneModel()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getPhoneModel() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        status: '',
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_phone_models.get(data).then(res => {
        this.phone_models = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
    getPhoneBrand() {
      this.$store.state.isLoading = true

      instance_phone_brands.all().then(res => {
        this.phone_brands = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
